<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.ware-received-and-delivery-report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row v-if="!authUser.warehouse_id">
            <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
              >
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('org_pro_division.division')"
                    label-for="division_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.division_id"
                    :options="divisionList"
                    id="division_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
                >
                <b-form-select
                plain
                v-model="search.region_id"
                :options="regionLists"
                id="division_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
                >
                <b-form-select
                plain
                v-model="search.district_id"
                :options="districtList"
                id="district_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
                >
                <b-form-select
                plain
                v-model="search.upazilla_id"
                :options="upazilaList"
                id="upazilla_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
                >
                <b-form-select
                plain
                v-model="search.union_id"
                :options="unionList"
                id="union_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      label-for="commodity_group_id"
                      :label="$t('warehouse_config.warehouse_type')"
                      >
                      <b-form-select
                      plain
                      v-model="search.warehouse_type_id"
                      :options="warehouseTypeList"
                      id="commodity_group_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="warehouse_id"
                    :label="$t('warehouse_information.warehouse_name')"
                >
                    <b-form-select
                        plain
                        v-model="search.warehouse_id"
                        :options="warehouseList"
                        id="warehouse_id"
                        v-if="!authUser.warehouse_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <b-form-select
                        plain
                        v-model="search.warehouse_id"
                        :options="warehouseList"
                        id="warehouse_id"
                        v-else
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.from_date')"
                label-for="from_date"
                >
                <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.to_date')"
                label-for="to_date"
                >
                <flat-pickr class="form-control"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="8">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.ware-received-and-delivery-report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="far fa-file-pdf"></i>{{ $t('globalTrans.export_pdf') }}
            </b-button>
              <export-excel
                class="btn btn_add_new"
                :data="dataCustomizeExcel"
                :fields="json_fields"
                :title= "headerInfo"
                worksheet="Warehouse Wise Product Receive and Delivery Report"
                name="product-receive-delivery-report.xls">
                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.ware-received-and-delivery-report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.organization') }}: </strong> {{ getOrganizationName(search.org_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.from_date') }}: </strong> {{ getSearchDate(search.from_date) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.to_date') }}: </strong> {{ getSearchDate(search.to_date) }}
                </b-col>
              </b-row>
              <b-row mt-5>
                <b-col md="6" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive>
                        <caption class="text-center" style="font-weight:bold; text-color:white">{{ $t('warehouse_report.receive_information') }}</caption>
                        <b-thead>
                          <b-tr>
                            <b-th style="width:20%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="width:20%">{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th style="width:20%">{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th style="width:30%" class="text-right">{{ $t('warehouse_report.current_receive_quantity') }}</b-th>
                            <b-th style="width:20%" class="text-right">{{ $t('warehouse_report.total_farmer') }}</b-th>
                          </b-tr>
                        </b-thead>
                         <b-tbody  v-for="(info, index) in receiveInformations" :key="index + 99">
                          <template v-for="(infoDetail, indx) in info.data">
                                <b-tr :key="index + indx + 200" v-if="indx">
                                  <b-td>{{ currentLocale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.current_quantity) }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                                <b-tr :key="index + indx + 999" v-else class="dfs">
                                  <b-th :rowspan="info.total+1">{{ $n(index+1) }}</b-th>
                                  <b-th :rowspan="info.total+1"><span class="capitalize">{{ currentLocale === 'en' ? info.crop_type_name : info.crop_type_name_bn }}</span></b-th>
                                  <b-td :key="indx + 9999">{{ currentLocale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.current_quantity) }}</b-td>
                                  <b-td class="text-right" :key="indx + 99999">{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                          <b-tfoot>
                          <b-tr>
                            <b-td colspan="3" variant="secondary" class="text-left">
                              {{ $t('sitePreference.grossTotal') }}: <b></b>
                            </b-td>
                            <b-td variant="secondary" class="text-right">{{ $n(currentQuantity) }}</b-td>
                            <b-td variant="secondary" class="text-right">{{ $n(totalFarmer) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
                <b-col md="6" class="table-responsive" style="margin-left:-30px">
                  <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <caption class="text-center" style="font-weight:bold">{{ $t('warehouse_report.delivery_information') }}</caption>
                        <b-thead>
                          <b-tr>
                            <b-th style="width:20%">{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th style="width:20%">{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th style="width:30%" class="text-right">{{ $t('warehouse_report.current_delivery_quantity') }}</b-th>
                            <b-th style="width:20" class="text-right">{{ $t('warehouse_report.total_farmer') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in deliveryInformations" :key="index + 999999">
                          <template v-for="(infoDetail, indx) in info.data">
                                <b-tr :key="index + indx + 1500" v-if="indx">
                                  <b-td>{{ currentLocale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.current_quantity) }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                                <b-tr :key="index + indx + 2500" v-else class="dfs">
                                  <b-th :rowspan="info.total+1"><span class="capitalize">{{ currentLocale === 'en' ? info.crop_type_name : info.crop_type_name_bn }}</span></b-th>
                                  <b-td :key="indx + 3500">{{ currentLocale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</b-td>
                                  <b-td class="text-right">{{ $n(infoDetail.current_quantity) }}</b-td>
                                  <b-td class="text-right" :key="indx + 850">{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                          <b-tfoot>
                          <b-tr>
                            <b-td colspan="2" variant="secondary" class="text-left">
                              {{ $t('sitePreference.grossTotal') }}: <b></b>
                            </b-td>
                            <b-td variant="secondary" class="text-right">{{ $n(deliveryCurrentQuantity) }}</b-td>
                            <b-td variant="secondary" class="text-right">{{ $n(deliveryTotalFarmer) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, receivedReportList, DeliveryReportList } from '../../api/routes'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Crop Type': 'crop_type',
  'Crop Name': 'crop_name',
  'Current Receive Quantity(Quintal)': 'current_receive_quantity',
  'Total Farmer': 'total_farmer',
  'Crop Type ': 'crop_type2',
  'Crop Name ': 'crop_name2',
  'Current Delivery Quantity(Quintal)': 'current_delivery_quantity',
  'Total Farmer ': 'total_farmer2'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'ফসলের ধরণ': 'crop_type',
  'ফসলের নাম': 'crop_name',
  'বর্তমান প্রাপ্ত পরিমাণ(কুইন্টাল)': 'current_receive_quantity',
  'মোট কৃষক': 'total_farmer',
  'ফসল ধরণ': 'crop_type2',
  'ফসল নাম': 'crop_name2',
  'বর্তমান বিতরণ পরিমাণ(কুইন্টাল)': 'current_delivery_quantity',
  'মোট ': 'total_farmer2'
}

Vue.use(excel)
export default {
  components: { ListReportHead },
  data () {
    return {
      currentQuantity: 0,
      totalFarmer: 0,
      deliveryCurrentQuantity: 0,
      deliveryTotalFarmer: 0,
      receiveInformations: [],
      deliveryInformations: [],
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        region_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        from_date: '',
        to_date: ''
      },
      productReport: [],
      organization: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      districtLoading: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
     json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.organization') + ' : ' + this.getOrganizationName(this.search.org_id) + ' ' + this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id)
      const secondRow = this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id) + ' ' + this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id)
      const thirdRow = this.$t('globalTrans.from_date') + ' : ' + this.getSearchDate(this.search.from_date) + ' ' + this.$t('globalTrans.to_date') + ' : ' + this.getSearchDate(this.search.to_date)
      return [this.$t('warehouse_report.ware-received-and-delivery-report'), '', firstRow, secondRow, thirdRow, '']
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    warehouseTypeList: function () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return listObject
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    dataCustomizeExcel () {
      var serial = 0
      const parentRows = []
      this.receiveInformations.forEach((typeData, typeIndx) => {
        const deliveryTypeData = this.deliveryInformations[typeIndx] ? this.deliveryInformations[typeIndx] : {}
        let totalFarmerReceive = 0
        let totalCurrentReceive = 0
        let totalFarmerDelivery = 0
        let totalCurrentDelivery = 0
        let rowSpan = typeData.total
        serial++
        typeData.data.forEach((listItem, indx) => {
          const item = {}

          if (rowSpan) {
            item.serial = this.$n(serial)
            item.crop_type = this.currentLocale === 'bn' ? typeData.crop_type_name_bn : typeData.crop_type_name
          } else {
            item.serial = ''
            item.crop_type = ''
          }
          const deliveryItem = deliveryTypeData.data ? deliveryTypeData.data[indx] : {}
          totalFarmerReceive = totalFarmerReceive + listItem.farmer_number
          totalCurrentReceive = totalCurrentReceive + listItem.current_quantity
          if (deliveryItem.farmer_number !== undefined && deliveryItem.farmer_number) {
            totalFarmerDelivery = totalFarmerDelivery + deliveryItem.farmer_number
          }
          if (deliveryItem.farmer_number !== undefined && deliveryItem.current_quantity) {
            totalCurrentDelivery = totalCurrentDelivery + deliveryItem.current_quantity
          }
          item.crop_name = this.currentLocale === 'bn' ? listItem.crop_name_bn : listItem.crop_name
          item.current_receive_quantity = this.$n(listItem.current_quantity)
          item.total_farmer = this.$n(listItem.farmer_number)
          if (rowSpan) {
            rowSpan = 0
            item.crop_type2 = this.currentLocale === 'bn' ? deliveryTypeData.crop_type_name_bn : deliveryTypeData.crop_type_name
          } else {
            item.crop_type2 = ''
          }
          item.crop_name2 = (deliveryItem.crop_name_bn || deliveryItem.crop_name) ? (this.currentLocale === 'bn' ? deliveryItem.crop_name_bn : deliveryItem.crop_name) : ''
          item.current_delivery_quantity = deliveryItem.current_quantity !== 'undefined' ? this.$n(deliveryItem.current_quantity) : ''
          item.total_farmer2 = deliveryItem.farmer_number !== 'undefined' ? this.$n(deliveryItem.farmer_number) : ''
          parentRows.push(item)
        })
      })

      const item = {}
      item.serial = this.$t('sitePreference.grossTotal')
      item.crop_type = ''
      item.crop_name = ''
      item.current_receive_quantity = this.$n(this.currentQuantity)
      item.total_farmer = this.$n(this.totalFarmer)
      item.crop_type2 = this.$t('sitePreference.grossTotal')
      item.crop_name2 = ''
      item.current_delivery_quantity = this.$n(this.deliveryCurrentQuantity)
      item.total_farmer2 = this.$n(this.deliveryTotalFarmer)
      parentRows.push(item)

      return parentRows
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.districtList = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
  },
  created () {
    // this.loadData()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    searchData () {
      if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.region_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0 ||
        this.search.warehouse_type_id !== 0 || this.search.warehouse_id !== 0 || this.search.from_date || this.search.to_date) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      }
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const response = await RestApi.getData(warehouseServiceBaseUrl, receivedReportList, this.search)
      if (response.success) {
        this.receiveInformations = response.data
        this.receiveList(this.receiveInformations)
      } else {
        this.receiveInformations = []
        this.currentQuantity = 0
        this.totalFarmer = 0
      }
      const result = await RestApi.getData(warehouseServiceBaseUrl, DeliveryReportList, this.search)
      if (result.success) {
        this.deliveryInformations = result.data
        this.deliveryList(this.deliveryInformations)
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      } else {
        this.deliveryInformations = []
        this.deliveryCurrentQuantity = 0
        this.deliveryTotalFarmer = 0
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }
    },
    receiveList (data) {
      this.currentQuantity = 0
      this.totalFarmer = 0
      var tF = 0
      var recQ = 0
      data.forEach((item) => {
        item.data.forEach((list) => {
           tF = tF + parseFloat(list.farmer_number)
           recQ = recQ + parseFloat(list.current_quantity)
        })
      })
      this.currentQuantity = recQ
      this.totalFarmer = tF
    },
    deliveryList (data) {
      var totalFarmer = 0
      var current = 0
      data.forEach((item) => {
        item.data.forEach((list) => {
           totalFarmer = totalFarmer + parseFloat(list.farmer_number)
           current = current + parseFloat(list.current_quantity)
        })
       })
      this.deliveryCurrentQuantity = current
      this.deliveryTotalFarmer = totalFarmer
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const activeData = response.filter(document => document.status === 0)
          const dataObject = activeData.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
        this.districtLoading = false
      })
    },
    getHeaderExcel () {
        const date = new Date().toLocaleDateString('en-US')
            const warehousenamebn = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id) ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_bn : null
            const warehousename = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id) ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_en : null
            const farmername = this.farmerDetail ? this.farmerDetail.name : null
            const farmernamebn = this.farmerDetail ? this.farmerDetail.name_bn : null
            const mobile = this.$i18n.locale === 'bn' ? '০' + this.$n(this.farmerDetail.username, { useGrouping: false }) : '0' + this.$n(this.farmerDetail.username, { useGrouping: false })
            const father = this.$i18n.locale === 'bn' ? this.farmerDetail.father_name_bn : this.farmerDetail.father_name
            const address = this.$i18n.locale === 'bn' ? this.farmerDetail.address_bn : this.farmerDetail.address
            const land = this.$n(this.farmerDetail.land_area)

        const head = [{ store_date: this.$t('globalTrans.date'), crop_type_id: date, crop_id: '', total_quantity: '', release_quantity: '', remaining_quantity: '' },
            { store_date: this.$t('warehouse_report.warehouse_name'), crop_type_id: this.$store.locale === 'en' ? warehousename : warehousenamebn, crop_id: '', total_quantity: this.$t('warehouse_report.farmer_name'), release_quantity: this.$store.locale === 'bn' ? farmernamebn : farmername, remaining_quantity: '' },
            { store_date: this.$t('warehouse_report.father_name'), crop_type_id: father, crop_id: '', total_quantity: this.$t('warehouse_report.mobile_number'), release_quantity: mobile, remaining_quantity: '' },
            { store_date: this.$t('warehouse_report.address'), crop_type_id: address, crop_id: '', total_quantity: this.$t('warehouse_report.land_area'), release_quantity: land, remaining_quantity: '' }]

        return head
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const totalRow = [
        { text: this.$t('sitePreference.grossTotal'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), colSpan: 3 },
        {},
        {},
        { text: this.$n(this.currentQuantity) },
        { text: this.$n(this.totalFarmer) },
        {},
        {},
        { text: this.$n(this.deliveryCurrentQuantity) },
        { text: this.$n(this.deliveryTotalFarmer) }
      ]
      rowData.push(totalRow)

      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.current_receive_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_farmer'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.current_delivery_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_farmer'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
          ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Wise Product Received and Delivery Report' : 'গুদাম অনুযায়ী পণ্য প্রাপ্ত এবং বিতরণ রিপোর্ট'
      const columnWidths = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
      const header1 = [
        { text: `${this.$t('globalTrans.organization')} : ${this.getOrganizationName(this.search.org_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header3 = [
        { text: `${this.$t('globalTrans.from_date')} : ${this.getSearchDate(this.search.from_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.to_date')} : ${this.getSearchDate(this.search.to_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2, header3]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWidths, extraData, 'product-receive-delivery-report')
    },
    getPdfData () {
      var serial = 0
      const parentRows = []
      this.receiveInformations.forEach((typeData, typeIndx) => {
        const deliveryTypeData = this.deliveryInformations[typeIndx]
        let totalFarmerReceive = 0
        let totalCurrentReceive = 0
        let totalFarmerDelivery = 0
        let totalCurrentDelivery = 0
        let rowSpan = typeData.total
        let rows = []
        typeData.data.forEach((listItem, indx) => {
            if (!rowSpan) {
              rows = []
            }
            serial++
            rows.push({ text: this.$n(serial) })
            if (rowSpan) {
              rows.push({ rowSpan: typeData.total, text: this.currentLocale === 'en' ? typeData.crop_type_name : typeData.crop_type_name_bn })
            } else {
              rows.push({ text: this.currentLocale === 'en' ? typeData.crop_type_name : typeData.crop_type_name_bn })
            }
            const deliveryItem = deliveryTypeData.data[indx]
            totalFarmerReceive = totalFarmerReceive + listItem.farmer_number
            totalCurrentReceive = totalCurrentReceive + listItem.current_quantity
            totalFarmerDelivery = totalFarmerDelivery + deliveryItem.farmer_number
            totalCurrentDelivery = totalCurrentDelivery + deliveryItem.current_quantity
            rows.push({ text: this.currentLocale === 'en' ? listItem.crop_name : listItem.crop_name_bn })
            rows.push({ text: this.$n(listItem.current_quantity) })
            rows.push({ text: this.$n(listItem.farmer_number) })
            if (rowSpan) {
              rowSpan = 0
              rows.push({ rowSpan: deliveryTypeData.total, text: this.currentLocale === 'en' ? deliveryTypeData.crop_type_name : deliveryTypeData.crop_type_name_bn })
            } else {
              rows.push({ text: this.currentLocale === 'en' ? deliveryTypeData.crop_type_name : deliveryTypeData.crop_type_name_bn })
            }
            rows.push({ text: this.currentLocale === 'en' ? deliveryItem.crop_name : deliveryItem.crop_name_bn })
            rows.push({ text: this.$n(deliveryItem.current_quantity) })
            rows.push({ text: this.$n(deliveryItem.farmer_number) })
            parentRows.push(rows)
          })
      })
      return parentRows
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getOrganizationName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.organizationtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getSearchDate (date) {
      let dateValue = this.$t('globalTrans.all')
      if (date) {
        dateValue = this.$options.filters.dateFormat(date)
      }
      return dateValue
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
